<template>
  <div class="integral_list_page">
    <myTable
      :title="
        $route.query.type === 'teacher'
          ? '老师积分日志'
          : $route.query.type === 'stu'
          ? '学生积分日志'
          : ''
      "
      :list="list"
      :columns="
        $route.query.type === 'teacher'
          ? columnsTeacher
          : $route.query.type === 'stu'
          ? columnsStu
          : ''
      "
    >
      <template slot="top_block" v-if="$route.query.type === 'stu'">
        <div class="table_btns">
          <el-input
            v-model="keyWord"
            type="text"
            placeholder="请输入学生姓名搜索"
          ></el-input>
          <el-button @click="getStuInfo" class="table_top_btn_default"
            >立即提交</el-button
          >
        </div>
      </template>
      <template slot="btns" slot-scope="item">
        <div class="table_btns">
          <div class="table_btn" @click="handleView(item.data.row)">查看</div>
        </div>
      </template>
    </myTable>
    <comPagination :params="params" @changePage="changePage"></comPagination>
  </div>
</template>

<script>
import myTable from "@/components/admin/table.vue";
import comPagination from "@/components/admin/pagination.vue";
export default {
  name: "headerSchool",
  components: {
    comPagination,
    myTable,
  },
  data() {
    return {
      columnsTeacher: [
        {
          prop: "name",
          label: "老师姓名",
        },
        {
          prop: "integral",
          label: "老师积分",
        },
      ],
      columnsStu: [
        {
          prop: "name",
          label: "学生姓名",
        },
        {
          prop: "integral",
          label: "学生积分",
        },
      ],
      list: [],
      //分页
      params: {
        page: 0,
        size: 10,
        total: 0,
      },

      // 关键字
      keyWord: "",
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    //初始化
    init() {
      if (this.$route.query.type === "teacher") {
        this.getTeacherInfo();
      } else {
        this.getStuInfo();
      }
    },
    getTeacherInfo() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.admin.findTeacherRanking(this.params, data).then((res) => {
        console.log(res);
        this.list = res.data.list;
        this.params.page = res.data.pageNum;
        this.params.total = res.data.total;
      });
    },
    getStuInfo() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
        name: this.keyWord || "",
      };
      this.api.admin.findStudentRanking(this.params, data).then((res) => {
        this.keyWord = "";
        console.log(res);
        this.list = res.data.list;
        this.params.page = res.data.pageNum;
        this.params.total = res.data.total;
      });
    },
    handleView(val) {
      console.log(val, "积分");
      if (this.$route.query.type === "teacher") {
        this.$router.push({
          path: "/admin/integralDetailList",
          query: {
            types: "teacher",
            ...val,
          },
        });
      } else {
        this.$router.push({
          path: "/admin/integralDetailList",
          query: {
            types: "stu",
            ...val,
          },
        });
      }
    },

    //分页 todo 待测试
    changePage(val) {
      this.params.page = val;
      if (this.$route.query.type === "teacher") {
        this.getTeacherInfo();
      } else {
        this.getStuInfo();
      }
    },
  },
};
</script>

<style lang="scss">
.integral_list_page {
  .table_btns {
    .el-input__inner {
      height: 60px;
      width: 250px;
      font-size: 20px;
    }
  }
}
</style>