var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"integral_list_page"},[_c('myTable',{attrs:{"title":_vm.$route.query.type === 'teacher'
        ? '老师积分日志'
        : _vm.$route.query.type === 'stu'
        ? '学生积分日志'
        : '',"list":_vm.list,"columns":_vm.$route.query.type === 'teacher'
        ? _vm.columnsTeacher
        : _vm.$route.query.type === 'stu'
        ? _vm.columnsStu
        : ''},scopedSlots:_vm._u([{key:"btns",fn:function(item){return [_c('div',{staticClass:"table_btns"},[_c('div',{staticClass:"table_btn",on:{"click":function($event){return _vm.handleView(item.data.row)}}},[_vm._v("查看")])])]}}])},[(_vm.$route.query.type === 'stu')?_c('template',{slot:"top_block"},[_c('div',{staticClass:"table_btns"},[_c('el-input',{attrs:{"type":"text","placeholder":"请输入学生姓名搜索"},model:{value:(_vm.keyWord),callback:function ($$v) {_vm.keyWord=$$v},expression:"keyWord"}}),_c('el-button',{staticClass:"table_top_btn_default",on:{"click":_vm.getStuInfo}},[_vm._v("立即提交")])],1)]):_vm._e()],2),_c('comPagination',{attrs:{"params":_vm.params},on:{"changePage":_vm.changePage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }